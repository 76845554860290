import React from "react";
import { useState, useEffect } from "react";
import configData from "../config.json";
import "../css/aboutUsInfo.css";
import reinisIndans from '../assets/Reinis_Indans_720x720.jpg';
import tomsGalinauskis from '../assets/Toms_Galinauskis_720x720.jpg';
import linardsKalnins from '../assets/Linards_Kalnins_720x720.jpg';
import girtsDalbergs from '../assets/Girts_Dalbergs_572x572.jpg';

const AboutUsInfo = (props) => {
  const [counts, setCounts] = useState(null); // Initialize counts as null
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const countsResponse = await fetch(configData.SERVER_URL+'counts/');
        if (countsResponse.ok) {
          const countsData = await countsResponse.json();
          setCounts(countsData);
          setLoading(false);
        } else {
          // Handle error responses here
          setLoading(false);
        }
      } catch (error) {
        // Handle fetch errors here
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }
  
  // Determine whether to display "grupa/grupas", "mūziķis/mūziķi" or "saite/saites"
  const bandsCountText = counts.bands_count.toString().endsWith("1") ? "grupa" : "grupas";
  const musiciansCountText = counts.musicians_count.toString().endsWith("1") ? "mūziķis" : "mūziķi";
  const relationsCountText = counts.relations_count.toString().endsWith("1") ? "saite" : "saites";

    return (
      <div className="aboutUsInfoContainer">
        <h1 className="aboutTitle">Par projektu</h1>

        
          <div className="aboutProject">
            <p>Projekts <b>Grupu saites</b> apkopo ar Latviju saistīto mūzikas grupu sakarības interaktīvas vizualizācijas veidā. <br/>
             Projektā pašlaik apkopotas <b>{counts.bands_count} {bandsCountText}</b>, kurās kopā spēlē <b>{counts.musicians_count} {musiciansCountText}</b>, veidojot savā starpā <b>{counts.relations_count} {relationsCountText}</b>.</p>
          </div>
        <h1 className="aboutTitle">Komanda</h1>
          <div className="aboutStaff">
            <div className="grid-item">
            <center><img className="teamImage" src={linardsKalnins}></img>
              <h2 className="aboutSubtitle">Linards Kalniņš</h2>
            <p>Izdomāja</p></center>
            </div>
            <div className="grid-item">
            <center><img className="teamImage" src={tomsGalinauskis}></img>           
            <h2 className="aboutSubtitle">Toms Gaļinauskis</h2>
            <p>Izdarīja</p></center>
            </div>
            <div className="grid-item">
            <center><img className="teamImage" src={reinisIndans}></img>          
            <h2 className="aboutSubtitle">Reinis Indāns</h2>
            <p>Vizualizēja</p></center>
            </div>
            <div className="grid-item">
            <center><img className="teamImage" src={girtsDalbergs}></img>        
            <h2 className="aboutSubtitle">Ģirts Dālbergs</h2>
            <p>Nostiprināja</p></center>
            </div>
          </div>
        <h1 className="aboutTitle"></h1>
        <center><p><b>Paldies par palīdzību:</b></p></center>                
          <div className="aboutContributors">
            <ul className="contributors">
            </ul>
            <p>Laurim Anstrautam, Normundam Balodim, Sandai Blomkalnai, Jānim Burmeisteram, Jānim Bušam,
               Kasparam Čakstem, Jānim Daugavietim, Ventam Feldmanim, Gatim Gavaram, Džuliannai Grečinai,
               Rolandam Ignatjevam, Jānim Jansonam, Oskaram Jansonam, Reinim Jaunajam, Renātei Kalniņai,
               Jānim Kalvānam, Raivim Kapilinskim, Emīlam "Popam" Koļesņikovam, Edvardam Kukam, Kristapam Kupšānam,
               Raimondam "Dambim" Lagimovam, Jānim Lazovskim, Mārtiņam Locam, Laumai Mežai, Jurģim Rubīnam,
               Uldim Rudakam, Kārlim Tonem, Didzim Upenam, Indriķim Veitneram, Mārtiņam Vilnītim,
               Ievai Vīverei, Laurai Ziemelei</p>
          </div>
          <h1 className="aboutTitle">Kontakti</h1>
          <div className="aboutSuggestions">
            <center><p>Neredzi sevi?</p></center><p>Sūti e-pastu uz <a href="mailto:info@grupusaites.lv">info@grupusaites.lv</a> vai izmanto datu papildināšanas <a href="https://docs.google.com/forms/d/e/1FAIpQLSfhfkmCQaE1QTwwfwXHsVFe2YIQ3MdcC0u_TOb64F1mjr8jkA/viewform">anketu</a></p>
          </div>
      </div>
    );
}

export default AboutUsInfo
