// Deals with graph layout and any data logic

import React, { useRef, useEffect, useState } from "react";

import { runForceGraph } from "./MainGraphGenerator";

import "../css/mainGraph.css";

const MainGraph = ({
  linksData,
  nodesData,
  groupInfo,
  filterCheckState,
  selectedBand,
  handleGraphTransform,
  graphContainerTransform,
  zoomToSelectedBand,
  changeZoomToSelectedBand,
}) => {
  const [previousNodes, setPreviousNodes] = useState();

  const handlePreviousNodeChange = (nodes) => {
    setPreviousNodes(nodes);
  };

  const containerRef = useRef(null);
  //console.log(nodesData)
  useEffect(() => {
    try {
      // remove the previous graph
      containerRef.current.removeChild(containerRef.current.children[0]);
    } catch {
      console.log("failed removing ref to graph container");
    }

    let destroyFn;

    if (containerRef.current) {
      const { destroy } = runForceGraph(
        containerRef.current,
        linksData,
        nodesData,
        groupInfo,
        filterCheckState,
        selectedBand,
        previousNodes,
        handlePreviousNodeChange,
        handleGraphTransform,
        graphContainerTransform,
        zoomToSelectedBand,
        changeZoomToSelectedBand,
      );
      destroyFn = destroy;
    }

    return destroyFn;
  }, [
    //here only the variables that should trigger a re-render of graph

    filterCheckState["filters"],
    filterCheckState["visiChecked"],
    selectedBand,
  ]);

  return (
    <div ref={containerRef} id={"graphContainer"} className={"container"} />
  );
};

export default MainGraph;
