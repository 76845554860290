import React, { useState } from "react";

import "./css/app.css"

import MainScreen from "./components/MainScreen"

import "./css/mainDiv.css";


function App() {
  return (
      <MainScreen />
  );
}

export default App;
