import React from "react";
import Switch from "@mui/material/Switch";
import { ThemeProvider } from "@mui/material/styles";
import { mainTheme } from "../constants/visualThemes"
import "../css/optionsBar.css";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { filter } from "d3";

const OptionsBar = (props) => {
 
  const handleFilterChange = (name) => {

    props.handleFilterChange(name);
  };


  const get_categories = () => {
    let categories = [];
    for (const filter in props.filters) {
      if (!categories.includes(props.filters[filter].category)) {
        categories.push(props.filters[filter].category);
      }
    }
    return categories;
  }

  const get_items_in_category = (category) => {
    let items = [];
    for (const filter in props.filters) {
      if (props.filters[filter].category === category) {
        items.push(filter);
      }
    }
    return items;
  }

  const render_all_categories = () => {
    let categories = get_categories();
    return (
      <div>
        {categories.map((category) => {return (render_category(category))})}
      </div>
        )
    }


  const render_category = (category) => {
    return (
      <div className="categoryContainer" key={category}>
        <Accordion className="accordion">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>{category}</AccordionSummary>
        <AccordionDetails>
          {get_items_in_category(category).map((item) => {
            return (
              <div key={item}>
                <div>{props.filters[item].alias}</div>
                <ThemeProvider theme={mainTheme}>
                  <Switch
                    checked={props.filters[item].checked}
                    onChange={()=>handleFilterChange(item)}
                    color={props.filters[item].color}
                  />
                </ThemeProvider>
              </div>
            );
          }
          )}
        </AccordionDetails>
        </Accordion>
      </div >
    )
  }

  return (
    <div className="optionsLayout">
      <div>Visas grupas</div>

      
      <ThemeProvider theme={mainTheme}>
        <Switch
          checked={props.visiChecked}
          onChange={props.visiOnChange}
          color={"nodes"}
        />
      </ThemeProvider>


      {render_all_categories()}
      
    </div>
  );
};

export default OptionsBar;
