import React from "react";
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";

import saitesLoadingLogo from '../assets/grupusaites-welcome-light-1.svg';
import { mainTheme } from "../constants/visualThemes"
import "../css/loadingScreen.css"

const LoadingScreen = () => {
    const backgroundColor = mainTheme.palette.primary.main
    return (<div className="loadingContainer">
        <img className="loadLogo" src={saitesLoadingLogo} alt="Logo" />
        <Dots className="loadingDots" color={backgroundColor} size={32} speed={1} animating={true} />
    </div>)
}


export default LoadingScreen;