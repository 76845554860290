import React, { useEffect, useState } from "react";

import { Autocomplete, TextField } from "@mui/material";


import Header from "./Header"
import MainGraph from "./MainGraph";
import GroupInfo from "./GroupInfo";
import AboutUsInfo from "./AboutUsInfo";
import OptionsBar from "./OptionsBar";
import AlertPopup from './AlertPopup';
import SearchBar from './SearchBar';
import useWindowDimensions from "../Hooks/UseWindowDimensions"

import configData from "../config.json";
import "../css/mainDiv.css";


import LoadingScreen from "./LoadingScreen";

var filterDeclarations = {
  "adminu_4": { alias: "Ādmiņu 4", color: "adminu_4", category: "Kopienas", checked: false },
  "folkloras_kopa": { alias: "Etnomūzika", color: "folkloras_kopa", category: "Kopienas", checked: false },
  "lv_hip_hop": { alias: "Hiphops", color: "lv_hip_hop", category: "Kopienas", checked: false },
  //"jelgavas_scena": { alias: "Jelgava", color: "jelgavas_scena", category: "Kopienas", checked: true },
  "liepajroks": { alias: "Liepāja", color: "liepajroks", category: "Kopienas", checked: false },
  "jauna_scena": { alias: "Scēna", color: "jauna_scena", category: "Kopienas", checked: false },
  "smiltenes_valmieras_scena": { alias: "Smiltene/Valmiera", color: "smiltenesValmieras", category: "Kopienas", checked: false },
  "tornis_affiliated": { alias: "Tornis", color: "tornis", category: "Kopienas", checked: false },
  "trimda": { alias: "Trimda", color: "trimda", category: "Kopienas", checked: false },
  "turaidas_2a": { alias: "Turaidas 2a", color: "turaidas_2a", category: "Kopienas", checked: false },
  "austra_nominated": { alias: "Austras balvas nominanti", color: "austra", category: "Apbalvojumi", checked: true },
  //"metala_scena":{alias: "Metāls", color:"metala_scena", category:"Kopienas", checked: true},
}

const MainScreen = () => {
  const developmentMode = false;

  const { width } = useWindowDimensions();

  const [bands, setBands] = useState();
  const [musicians, setMusicians] = useState();
  const [relations, setRelations] = useState();
  const [dataCached, setDataCached] = useState(true);
  const [showGroupInfo, setShowGroupInfo] = useState(false);
  const [showAboutUsInfo, setShowAboutUsInfo] = useState(false);
  const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);
  const [selectedBand, setSelectedBand] = useState();
  const [visiChecked, setVisiChecked] = useState(false);
  const [filters, setFilters] = useState(filterDeclarations)

  const [graphContainerTransform, setGraphContainerTransform] = useState({
    x: 0,
    y: 0,
    scale: 1.6,
  });
  const [showOptionsBar, setShowOptionsBar] = useState(width > 500 ? true : false)
  const [zoomToSelectedBand, setZoomToSelectedBand] = useState(false)
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');

  const changeZoomToSelectedBand = (showOrNot) => {
    setZoomToSelectedBand(showOrNot)
  }

  const changeOptionsBarVisibility = () => {
    setShowOptionsBar(!showOptionsBar)
  }

  const changeAboutUsInfoVisibility = () => {
    setShowAboutUsInfo(!showAboutUsInfo)
  }

  const toggleSearchBar = () => {
    setIsSearchBarVisible((prevIsSearchBarVisible) => !prevIsSearchBarVisible)
  };

  const handleKeyDown = (event) => {
    if (event.ctrlKey && event.key === ' ') {
      toggleSearchBar();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const getGraphTransform = () => {
    return graphContainerTransform;
  };

  const groupInfoHandler = (bandId) => {
    // the band members are fetched based on bandId, passed from MainGraphGenerator

    // find the selected band info, based on band id
    let selectedBand = bands.filter((band) => band.id === bandId)[0];
    // fetch the data from backend
    fetch(
      configData.SERVER_URL +
      configData.RELATIONS_URL +
      configData.GET_BAND_MEMBERS,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ band: bandId }),
      }
    )
      .then((resp) => resp.json())
      .then((resp) => {
        // create an object with all the information that is needed for bandInfo window
        const band = {
          id: bandId,
          name: selectedBand.name,
          musicians: resp.result,
        };
        //show band info
        changeZoomToSelectedBand(true);
        setSelectedBand(band);
        setShowGroupInfo(true);
      })
      .catch((error) => console.log(error));
  };

  const updateSelectedBand = (newBand) => {
    if (newBand !== undefined && newBand !== null) {
      const filtered_bands = filterResults().nodesData
      if (filtered_bands.some(e => e.id == newBand.id)) {
        groupInfoHandler(newBand.id);
      }
      else {
        handleShowIncorrectFilterPopup()
      }
    } else {
      setShowGroupInfo(false);
      setSelectedBand(undefined);
    }
  }

  const closeBandInfo = () => {
    setShowGroupInfo(false);
  };

  const dataLoadingComplete = () => {
    return bands != null && musicians != null && relations != null;
  };

  useEffect(() => {
    // try to fetch data from cache, if available
    if (localStorage.getItem("relations") != null && developmentMode) {
      console.log("Data is cached");
      setBands(JSON.parse(localStorage.getItem("bands")));
      setMusicians(JSON.parse(localStorage.getItem("musicians")));
      setRelations(JSON.parse(localStorage.getItem("relations")));
    } else {
      console.log("data not cached");
      setDataCached(false);
    }
  }, []);

  useEffect(() => {
    // fetching the data from database on first run!! Empty dependency array means that it is being run only once!
    // fetch is a built in REST functionality, like AXIOS, HTTP_Request
    // Fetch all the necessary data from Database!
    // first argument is api url, second is ontions
    // remember to set the frontend server in the CORS_Whitelist @django backend settings
    // adding the cache, so that Database queries are only done on first run
    if (!dataCached) {
      let serverURL = configData.SERVER_URL;
      // if (developmentMode) {
      //  serverURL = "http://127.0.0.1:8000/api/";
      // }
      fetch(serverURL + configData.BANDS_URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((resp) => resp.json())
        .then((resp) => {
          localStorage.setItem("bands", JSON.stringify(resp));
          setBands(resp);
        })
        .catch((error) => console.log(error));

      fetch(configData.SERVER_URL + configData.MUSICIANS_URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((resp) => resp.json())
        .then((resp) => {
          localStorage.setItem("musicians", JSON.stringify(resp));
          setMusicians(resp);
        })
        .catch((error) => console.log(error));

      fetch(
        configData.SERVER_URL +
        configData.RELATIONS_URL +
        configData.GET_BAND_RELATIONS,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((resp) => resp.json())
        .then((resp) => {
          localStorage.setItem("relations", JSON.stringify(resp.result));
          setRelations(resp.result);
        })
        .catch((error) => console.log(error));
    }
  }, [dataCached]);

  const handleFilterChange = (filterName) => {
    let newFilters = { ...filters }
    newFilters[filterName]["checked"] = !newFilters[filterName]["checked"]
    setFilters(newFilters)
  }

  const handleVisiCheckedChange = () => {
    setVisiChecked(!visiChecked);
  };

  const handleGraphTransform = (transform) => {
    setGraphContainerTransform(transform);
  };

  const handlePopupClose = () => {
    setPopupOpen(false);
    setPopupMessage('');
  };

  const handleShowIncorrectFilterPopup = () => {
    setPopupMessage('Šī grupa nav izvēlētajos filtros. Pamēģiniet ieslēgt "Visas grupas" filtru un mēģiniet meklēt vēlreiz!');
    setPopupOpen(true);
  };


  const filterResults = () => {
    if (!visiChecked) {
      const filteredBands = bands.filter(

        (band) => filterBand(band)

      );
      const bandIds = filteredBands.map((band) => band.id);
      const linksArray = relations.filter((relation) => {
        return (
          bandIds.includes(relation.source) && bandIds.includes(relation.target)
        );
      });
      return {
        linksData: linksArray,
        nodesData: filteredBands,
      };
    }
    return {
      linksData: relations,
      nodesData: bands,
    };
  };

  const filterBand = (band) => {
    for (const filter in filters) {
      if (filters[filter].checked === true && band[filter] === true) {
        return true
      }
    }
    return false
  }


  return (
    <div className="mainContainer">
      <Header optionBarIconClick={changeOptionsBarVisibility} aboutUsIconClick={changeAboutUsInfoVisibility} />
      {dataLoadingComplete() ? (
        <div className="contentLayout">

          <AlertPopup
            message={popupMessage}
            open={popupOpen}
            handleClose={handlePopupClose}
          />
          {showOptionsBar && !showAboutUsInfo ? (<OptionsBar
            filters={filters}
            handleFilterChange={handleFilterChange}

            visiChecked={visiChecked}
            visiOnChange={handleVisiCheckedChange}
          />) : null}

          <div className="graphContainer">
            <MainGraph
              linksData={filterResults().linksData}
              nodesData={filterResults().nodesData}
              groupInfo={updateSelectedBand}
              filterCheckState={{
                filters: filters,
                visiChecked: visiChecked
              }}
              selectedBand={selectedBand}
              handleGraphTransform={handleGraphTransform}
              graphContainerTransform={getGraphTransform}
              zoomToSelectedBand={zoomToSelectedBand}
              changeZoomToSelectedBand={changeZoomToSelectedBand}
            />
            {!showAboutUsInfo && !isSearchBarVisible ? (<SearchBar selectedBand={selectedBand}
              bands={bands}
              updateSelectedBand={updateSelectedBand}/>
            ) : null}

          </div>
        </div>
      ) : (
        <LoadingScreen />
      )}
      {showAboutUsInfo ? (
        <AboutUsInfo closeAboutUsInfo={changeAboutUsInfoVisibility} />
      ) : null}

      {showGroupInfo && !showAboutUsInfo ? (
        <GroupInfo band={selectedBand} closeBandInfo={closeBandInfo} />
      ) : null}
    </div>

  );
};

export default MainScreen;
