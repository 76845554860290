import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar'

const AlertPopup = ({ message, open, handleClose }) => {
  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        handleClose();
      }, 5000); // Adjust the time (in milliseconds) as needed

      return () => clearTimeout(timer);
    }
  }, [open, handleClose]);

  return (
    <Snackbar
      open={open}
      message={message}
      autoHideDuration={null}
      onClose={handleClose}
    />
  );
};

export default AlertPopup;