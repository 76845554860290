import React from "react";

import { Autocomplete, TextField } from "@mui/material";
import { mainTheme } from "../constants/visualThemes"

const SearchBar = (props) =>{
return (
    <div className="bandSearchField">
              <Autocomplete
              sx={{
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: mainTheme.palette.primary.darker,
                    backgroundColor: mainTheme.palette.primary.darker,
                    opacity:0.1
                  },
                  "& label": {
                    "&.Mui-focused": {
                      color: mainTheme.palette.primary.main
                    }
                  }                  
              }}
                disablePortal
                options={props.bands}
                value={props.selectedBand !== undefined ? props.selectedBand : null}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params}
                   id="searchField"
                   label="Meklēt grupu"
                   hiddenLabel={false}
                  />
                )}
                onChange={(event, newValue) => {
                  props.updateSelectedBand(newValue)
                }}
              />
            </div>
)
}


export default SearchBar;