import React from "react";
import MenuIcon from '@mui/icons-material/Menu';
import Info from '@mui/icons-material/Info';

import {mainTheme} from "../constants/visualThemes"

import "../css/header.css";

const BackgroundImage= "../assets/grupusaitesCream.png"

const Header = (props) => {

  const handleOptionsBarIconClick = ()=>{
    props.optionBarIconClick()
  }

  const handleAboutUsIconClick = ()=>{
    props.aboutUsIconClick()
  }

  return (
    <header className="App-header" style={{backgroundColor:mainTheme.palette.primary.light, color: mainTheme.palette.primary.dark}}>
      <MenuIcon className="filterListIcon" style={{color: mainTheme.palette.primary.dark, fontSize:"3rem"}} onClick={() => { handleOptionsBarIconClick()}}/>
      <h1 className="headerTitle"></h1>
      <Info className="aboutUsIcon" style={{color: mainTheme.palette.primary.dark, fontSize:"3rem"}} onClick={() => { handleAboutUsIconClick()}}/>
    </header>
  );
};

export default Header;
