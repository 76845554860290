import React from "react";

import "../css/groupInfo.css";

const GroupInfo = (props) => {
    return (
      <div className="groupInfoContainer">
        <h1 className="title">{props.band.name}</h1>
        <h2 className="memberTitle">Dalībnieki:</h2>
        {props.band.musicians.map((input) => (
          <div className="memberText" key={input.id}>
            <div>{input.first_name}{" "}{input.last_name}</div>           
          </div>
        ))}
      </div>
    );

}


export default GroupInfo