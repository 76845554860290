// Generates the graph svg representation

import * as d3 from "d3";

import "../css/mainGraph.css";

import { mainTheme } from "../constants/visualThemes"

export function runForceGraph(
  container,
  linksData,
  nodesData,
  groupInfo,
  filterCheckState,
  selectedBand,
  previousNodes,
  handlePreviousNodeChange,
  handleGraphTransform,
  graphContainerTransform,
  zoomToSelectedBand,
  changeZoomToSelectedBand,
) {

  //get the container’s width and height:
  const containerRect = container.getBoundingClientRect();
  const height = containerRect.height; //containerRect.height;
  const width = containerRect.width; //containerRect.width;

  // set current container zoom and translate levels to be dynamic
  let initialTransform = graphContainerTransform();
  let currentTransform = initialTransform;

  //Style constants

  //Colors
  const color = mainTheme.palette.nodes.main;
  const backgroundColor = mainTheme.palette.primary.darker
  const highlightColor = mainTheme.palette.nodes.selected //mainTheme.palette.primary.selected;
  const linkColor = mainTheme.palette.primary.link;

  const linkSelectedColor = mainTheme.palette.primary.linkSelected
  const labelStroke = mainTheme.palette.primary.labelStroke;
  const labelFill = mainTheme.palette.primary.labelFill;
  const opacity = 0.5;

  //Sizes
  const nodeRadius = 12;
  const highlightNodeRadius = nodeRadius * 3
  const highlightNeighborNodeRadius = nodeRadius * 2


  //behaviour states
  let isDragging = false;
  let isMouseover = false;

  // Function to create the pieChart data structure
  const createBandPieChartData = (bandInfo) => {
    const bandPieChartOptions={}
    Object.keys(filterCheckState["filters"]).forEach(function(key, index) {
      const color=filterCheckState["filters"][key].color
      const pieChartColor=mainTheme.palette[color].main
      bandPieChartOptions[key] = {color:pieChartColor, value: 1 }
    });


    for (const bandIndex in bandInfo) {
      let pieChartData = []
      for (const key in bandPieChartOptions) {
        if (key in filterCheckState["filters"]) {
          if (bandInfo[bandIndex][key] && filterCheckState["filters"][key] && filterCheckState["filters"][key].checked) {
            const pieChartOption = { ...bandPieChartOptions[key], id: bandInfo[bandIndex].id }
            pieChartData.push(pieChartOption)
          }
        }
        bandInfo[bandIndex].pieChartData = pieChartData
      }
    }
    return bandInfo
  }

  //!!!!  copy the data
  const links = linksData.map((d) => Object.assign({}, d));
  let nodes = previousNodes;
  if (nodes === undefined || nodes.length !== nodesData.length) {
    // if this is the first run (nodes= undefined) or new node arrangement passed, redraw the graph from beginning
    nodesData = createBandPieChartData(nodesData)
    nodes = nodesData.map((d) => Object.assign({}, d));
  } else {
    nodes = createBandPieChartData(nodes)
    // there are previous nodes from last reload of graph
  }

  // pie-chart code:
  var pie = d3.pie()
    .sort(null)
    .value(function (d) { return d.value });

  // function to find neighboring nodes
  function neighboring(a, b) {
    return (
      linkedByIndex[a.id + "," + b.id] ||
      linkedByIndex[b.id + "," + a.id] //||
      //a.id == b.id
    );
  }

  const changeNodeColor = (d) => {
    // TODO find a better way to determine node color
    let nodeColor = color;
    try {
      // check if there is an explicitly selected band, change color if there is
      if (selectedBand !== undefined && d.id === selectedBand.id) {
        nodeColor = highlightColor;
      }
      else {
        nodeColor = color;
      }
    }
    catch { }
    return nodeColor;
  };

  const changeNodeRadius = (node) => {
    let newNodeRadius = nodeRadius;
    try {
      if (selectedBand !== undefined) {
        // check if there is an explicitly selected band, change radius if there is
        let selectedBandNode = nodes.filter((obj) => {
          return obj.id === selectedBand.id;
        })[0];
        // selected Band id in search bar
        if (selectedBand.id === node.id) {
          newNodeRadius = highlightNodeRadius;
        } else if (neighboring(node, selectedBandNode) && selectedBandNode.id !== node.id) {
          // neighboring nodes of the 3
          newNodeRadius = highlightNeighborNodeRadius;
        }
      }
    }
    catch { }
    return newNodeRadius;
  };

  const changeNodeOpacity = (node) => {
    let newOpacity = opacity;
    try {
      if (selectedBand !== undefined && node !== undefined) {
        let selectedBandNode = nodes.filter((obj) => {
          return obj.id === selectedBand.id;
        })[0];
        if (selectedBand.id === node.id || neighboring(node, selectedBandNode)) {
          newOpacity = 1;
        }
      }
    }
    catch { }
    return newOpacity;
  };

  const changeLinkColor = (link) => {
    let newLinkColor = linkColor;
    try {
      if (selectedBand !== undefined && link !== undefined) {
        let selectedBandNode = nodes.filter((node) => {
          return node.id === selectedBand.id;
        })[0];
        if (
          link.source === selectedBandNode ||
          link.target === selectedBandNode
        ) {
          newLinkColor = linkSelectedColor
            ;
        }
      }
    }
    catch { }
    return newLinkColor;
  };
  const changeLinkOpacity = (link) => {
    let newOpacity = opacity;
    try {
      if (selectedBand !== undefined && link !== undefined) {
        let selectedBandNode = nodes.filter((node) => {
          return node.id === selectedBand.id;
        })[0];
        if (
          link.source === selectedBandNode ||
          link.target === selectedBandNode
        ) {
          newOpacity = 1;
        }
      }
    }
    catch { }
    return newOpacity;
  };

  const getLabel = (d) => {
    try {
      if ((window.screen.width >= 820 && currentTransform.scale > 0.7) || (window.screen.width < 820 && currentTransform.scale > 0.4)) {
        return d.name;
      }
      // check if there is explicitly selected band, add label to if if there is
      else if (selectedBand !== undefined) {
        let selectedBandNode = nodes.filter((obj) => {
          return obj.id === selectedBand.id;
        })[0];
        // show name for the selected band and its neighbors
        if (d.id === selectedBand.id || neighboring(d, selectedBandNode)) {
          return d.name;
        }
      }
    }
    catch { }
  };

  const getLabelSize = (d) => {
    let labelSize = "1em"
    try {
      if (selectedBand !== undefined) {
        // show name for the selected band and its neighbors
        if (d.id === selectedBand.id) {
          labelSize = "2em";
        }
      }
    }
    catch { }
    return labelSize;
  }

  // !!!!!!!  helper functions for dragging the nodes:
  const drag = (simulation) => {
    const dragstarted = (event, d) => {
      isDragging = true;
      if (!event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    };

    const dragged = (event, d) => {
      d.fx = event.x;
      d.fy = event.y;
    };

    const dragended = (event, d) => {
      if (!event.active) simulation.alphaTarget(0);
      isDragging = false;
      d.fx = null;
      d.fy = null;
    };

    return d3
      .drag()
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", dragended);
  };

  //!!!!!!!! D3 code to generate the graph:

  //initial transform to apply:
  var transform = d3.zoomIdentity.translate(initialTransform.x, initialTransform.y).scale(initialTransform.scale);

  const handleZoom = (event) => {
    if (innerContainer) {
      innerContainer.attr("transform", event.transform);
      currentTransform = { x: event.transform.x, y: event.transform.y, scale: event.transform.k };
      try {
        innerContainer.selectAll(".labels")
          .text((d) => {
            return getLabel(d);
          })
      }
      catch {


      }

      handleGraphTransform(currentTransform);
    }
  }

  //Set zoom limits
  const zoom = d3.zoom()
    .scaleExtent([0.15, 8])
    .translateExtent([[-width * 10, -height * 10], [width * 10, height * 10]])
    .on("zoom", handleZoom)

  const svg = d3
    .select(container)
    .append("svg")
    .style("background-color", backgroundColor)
    .attr("preserveAspectRatio", "xMinYMin meet")
    .attr("viewBox", [-width, -height, width * 2, height * 2])
    .call(zoom)
    .call(zoom.transform, transform)


  var innerContainer = svg
    .append("g")
    .attr("id", "innerContainer")
    .attr("transform", d3.zoomIdentity.translate(currentTransform.x, currentTransform.y).scale(currentTransform.scale))

  // checking if should zoom to the selected band- either after searching for band, or on "click" event

  if (zoomToSelectedBand) {
    let selectedBandNode = nodes.filter((obj) => {
      return obj.id === selectedBand.id;
    })[0];
    const translateX = selectedBandNode.x
    const translateY = selectedBandNode.y

    svg.transition().duration(1000).call(zoom.translateTo, translateX, translateY)
    currentTransform.x = translateX
    currentTransform.y = translateY
    changeZoomToSelectedBand(false);
  }

  const simulation = d3
    .forceSimulation(nodes)
    .force(
      "link",
      d3.forceLink(links).id((d) => d.id)
    )
    .force("charge", d3.forceManyBody().strength(-1500))
    .force("x", d3.forceX())
    .force("y", d3.forceY());

  // draw link lines.
  const link = innerContainer
    .append("g")
    .selectAll("line")
    .data(links)
    .join("line")
    .attr("stroke-width", 1)
    .attr("stroke", function (d) {
      return changeLinkColor(d);
    })
    .attr("stroke-opacity", function (d) {
      return changeLinkOpacity(d);
    });

  // needed to establish connected nodes!
  const linkedByIndex = {};
  links.forEach(function (d) {
    linkedByIndex[d.source.id + "," + d.target.id] = 1;
  });

  // create node DOM structure
  const node = innerContainer
    .append("g")
    .selectAll(".node")
    .data(nodes)
    .attr("class", "node")

  // each node's constituent parts are under corresponding nodeElement
  const nodeElements = node.join("g")
    .attr("class", "singleNode")
    .call(drag(simulation));

  // draw the circles, they are each under single nodeElement
  const circles = nodeElements.append("circle")
    .attr("class", "circle")
    .attr("r", function (d) {
      return changeNodeRadius(d);
    })
    .attr("fill", function (d) {
      return changeNodeColor(d);
    })
    .attr("opacity", function (d) {
      changeNodeOpacity(d);
    })

  // Draw the pie graphs. They are each under single nodeElements, pies consist of several paths for single arcs (pie pieces)
  const pieGraphs = nodeElements.each(function (d, i) {
    if (d.pieChartData.length > 0) {
      let element = d3.select(this)

      var g = element.selectAll(".arc")
        .data(pie(d.pieChartData))
        .join("g")
        .attr("class", "arc");

      g.append("path")
        .attr("d", d3.arc()
          .outerRadius(changeNodeRadius(d))
          .innerRadius(changeNodeRadius(d) * 0.6))
        .attr("fill", (d) => { return d.data.color })
    }
    else {
      // no pie graph to draw
    }

  }
  );

  const labelCollisionForce = d3.forceCollide().radius(40); // Adjust the radius as needed
  const nodeCollisionForce = d3.forceCollide().radius(20); // Adjust the radius as needed

  simulation.force('circles', nodeCollisionForce);

  // Labels make it incomprehensible
  const label = nodeElements
    .append("text")
    .attr("class", "labels")
    .attr("text-anchor", "middle")
    .attr("dy", 40)
    .attr("pointer-events", "none")
    .attr("dominant-baseline", "central")
    .attr("font-size", (d) => { getLabelSize(d) })
    .attr("stroke", labelStroke)
    .attr("fill", labelFill)
    .text((d) => {
      return getLabel(d);
    })

  // Add label nodes to the collision force
  simulation.force('label', labelCollisionForce);

  const getMouseoverRadius = (o, d) => {

    let nodeSize = nodeRadius;
    if (neighboring(d, o)) { nodeSize = highlightNeighborNodeRadius }
    else if (o.id === d.id) {
      nodeSize = highlightNodeRadius;
    }
    else {
      nodeSize = changeNodeRadius(o)
    }
    return nodeSize;
  }

  // !!!!!!  Event handlers!!!!!
  nodeElements //or label
    .on("mouseover", (event, d) => {
      isMouseover = true;
      if (isDragging) {
        return
      }

      // change selected node color
      circles.style("fill", function (o) {
        const nodeColor = o.id === d.id ? highlightColor : changeNodeColor(o);
        return nodeColor;
      });

      // change node and pie graph opacity
      nodeElements.style("opacity", function (o) {
        let nodeOpacity =
          neighboring(d, o) || o.id === d.id ? 1 : changeNodeOpacity(o);
        return nodeOpacity;
      });

      //change node link color
      link.style("stroke", function (o) {
        let newLinkColor = changeLinkColor(o);
        if (o.source === d || o.target === d) {
          newLinkColor = linkSelectedColor;
        }
        return newLinkColor;
      });

      circles.style("r", function (o) {
        return getMouseoverRadius(o, d)
      });

      //Change pie graph sizes
      pieGraphs.selectAll("path").each(function (data) {
        try {
          const nodeRadius = getMouseoverRadius(data.data, d)

          d3.select(this).attr("d", d3.arc()
            .outerRadius(nodeRadius)
            .innerRadius(nodeRadius * 0.6))
        }
        catch {
          // no pie graph
        }
      })

      // add labels to neighbors
      label.text((o) => {
        return (neighboring(d, o) || o.id === d.id) ? o.name : getLabel(o);
      });
      label.attr("font-size", (o) => { return o.id === d.id ? "2em" : getLabelSize(o) })
      //change selected node size
    })

    .on("mouseout", (d) => {
      // restore original values, if not dragging
      isMouseover = false;
      if (!isDragging) {
        circles.style("r", function (d) {
          return changeNodeRadius(d);
        });
        circles.style("fill", function (d) {
          return changeNodeColor(d);
        });
        //set opacity for circles and pie graphs
        nodeElements.style("opacity", 1);
        link.style("stroke", function (d) {
          return changeLinkColor(d);
        });

        // change pie graph radius
        pieGraphs.selectAll("path").each(function (data) {
          try {
            const nodeRadius = changeNodeRadius(data.data)
            // draw the individual pie graph segments
            d3.select(this)
              .attr("d", d3.arc()
                .outerRadius(nodeRadius)
                .innerRadius(nodeRadius * 0.6))
          }
          catch {
            // no pie graph
          }
        })
        // Change text sizes
        label.text(function (d) {
          return getLabel(d);
        });
        label.attr("font-size", (o) => { return o.id === d.id ? "2em" : getLabelSize(o) })
      }
    })
    .on("click", (event, d) => {
      // show the band information on the main screen
      // getBandMembers fetches band members and calls function to display info
      handleGraphTransform(currentTransform);
      handlePreviousNodeChange(simulation.nodes())
      //set selected band
      groupInfo(d);
    })
    .on("zoom", () => {
      label.text(function (d) {
        return getLabel(d);
      })
    })

  svg.on("click", () => {
    if (!isMouseover) {
      // deselect selected band
      groupInfo();
    }
  });

  // run the simulation animation on each tick- update position of all elements
  simulation.on("tick", () => {
    //update link positions
    link
      .attr("x1", (d) => d.source.x)
      .attr("y1", (d) => d.source.y)
      .attr("x2", (d) => d.target.x)
      .attr("y2", (d) => d.target.y);

    pieGraphs.attr("transform", d => `translate(${d.x},${d.y})`);

    handlePreviousNodeChange(simulation.nodes())
  });

  ///!!!! Destroy function!!!

  return {
    destroy: () => {
      simulation.stop();
      handleGraphTransform(currentTransform);
      handlePreviousNodeChange(simulation.nodes());


    },
    nodes: () => {
      return innerContainer.node();
    },
  };
}
